/* Made with love by Mutiullah Samim*/
:root {
    --primary-color: #029fb2;
    --orange: #0178bc;
}

.login .login-cover .login-cover-bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.login input {
    font-size: 14px;
}

.login .login-cover {
    -webkit-transition: background 0.2s linear;
    -moz-transition: background 0.2s linear;
    -ms-transition: background 0.2s linear;
    -o-transition: background 0.2s linear;
    transition: background 0.2s linear;
}

.login .login-cover .login-cover-bg {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.45) 0%,
        rgba(0, 0, 0, 0.6)
    );
}

.login .login-bg-list > li a,
.login .login-cover .login-cover-bg,
.login-cover .login-cover-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.8;
}

.login .login-cover .login-cover-image {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.login .login-bg-list {
    list-style-type: none;
    position: absolute;
    right: 20px;
    bottom: 20px;
    margin: 0;
    padding: 0 0 0 20px;
    z-index: 1020;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.login .login-bg-list > li {
    width: 66px;
    height: 66px;
    overflow: hidden;
    border: 3px solid transparent;
    -webkit-border-radius: 66px;
    border-radius: 66px;
}

@media (max-width: 575.98px) {
    .login .login-bg-list > li {
        width: 46px;
        height: 46px;
    }
}

.login .login-bg-list > li a {
    display: block;
    height: 60px;
    overflow: hidden;
}

.login .login-bg-list > li img {
    max-height: 100%;
    max-width: 100%;
}

.login .login-bg-list > li:focus,
.login-bg-list > li:hover {
    border-color: rgba(255, 255, 255, 0.5);
}

.login .login-bg-list > li.active {
    border-color: #fff;
    z-index: 1020;
    position: relative;
}

.login .container {
    align-content: center;
}

.login .card {
    height: 370px;
    margin-top: 160px;
    margin-bottom: auto;
    width: 500px;
    background-color: rgba(0, 0, 0, 0.5) !important;
    border-color: var(--orange) !important;
}
.login .card-header {
    margin-top: 15px;
    background-color: rgba(255, 255, 255, 0) !important;
}

.login .social_icon span {
    font-size: 60px;
    margin-left: 10px;
    color: #ffc312;
}

.login .card-header h3 {
    color: white;
}

.login .social_icon {
    position: absolute;
    right: -5px;
    top: -70px;
}

.login .input-group-prepend span {
    width: 50px;
    height: 38px;
    background-color: var(--primary-color);
    color: black;
    border: 0 !important;
}

.login .ant-input-group-addon {
    width: 50px !important;
    background-color: var(--primary-color) !important;
    color: black !important;
    border: 0 !important;
}

.login input:focus {
    outline: 0 0 0 0 !important;
    box-shadow: 0 0 0 0 !important;
}

.login .remember {
    color: white;
}

.login .remember input {
    width: 20px;
    height: 20px;
    margin-left: 15px;
    margin-right: 5px;
}

.login button {
    color: white;
    background-color: var(--primary-color) !important;
    width: 100%;
}

.login .login_btn:hover {
    border-radius: 4px;
    color: black;
    background-color: var(--orange) !important;
}

.login .links {
    color: white;
}

.login .links a {
    margin-left: 4px;
}