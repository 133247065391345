@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,900;1,200;1,400;1,500;1,700&family=Philosopher:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Courgette&display=swap");

body {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Montserrat", sans-serif !important;
}

table td {
    font-family: "Montserrat", sans-serif !important;
    font-size: 14px;
}

.candidate .card {
    background: rgba(117, 133, 109, 0.082) none repeat scroll 0 0 !important;
    box-shadow: 2px 2px 2px 1px rgba(23, 31, 20, 0.2) !important;
}

#fondo {
    background-image: url(../../Assets/images/bg/cintillo.svg);
    background-position: center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: fixed;
    width: 100%;
    height: 100vh;
    right: 0px;
    left: 0px;
    top: 0px;
}

form {
    font-size: 14px;
}

.input-feedback {
    color: #ff0000;
}

.jconfirm-content span {
    color: orangered;
    font-style: italic;
    font-weight: 400;
}

#icon-info {
    margin-top: 15px;
    margin-bottom: 20px;
}

#icon-info h6 {
    font-family: "Philosopher", sans-serif !important;
    font-size: 700;
    color: #029fb2 !important;
}

#icon-info p {
    color: rgb(71, 97, 105);
}

#infoince {
    font-family: "Courgette";
    font-size: 18px;
}

#infoince p {
    margin-top: 150px;
    vertical-align: middle;
}

.programs {
    margin-top: 15px;
    padding-bottom: 30px;
}

#info-2 .card {
    height: 100%;
}

.card-header {
    background-color: #ffffff !important;
}

#cintillo {
    background-image: url(../../Assets/images/bg/bannerbolivariano.svg);
    background-position: 95px;
    background-repeat: no-repeat;
    background-color: rgb(242, 242, 242, 0.9);
    position: relative;
    height: 60px;
}

.pre-footer {
    font-family: "Montserrat", sans-serif !important;
    font-weight: 400;
    font-size: 13px;
    background-color: rgba(2, 159, 178) !important;
    color: white;
    bottom: 0;
    height: 70%;
    position: relative;
}

.pre-footer h4 {
    font-family: "Philosopher", sans-serif !important;
    font-weight: 400;
    color: white;
}
.pre-footer p {
    padding-left: 10px;
    padding-right: 25px;
    margin-top: 15px;
}
.pre-footer .tabulacion:first-letter {
    margin-left: 25px;
}

footer {
    font-family: "Philosopher", sans-serif !important;
    background-color: rgba(2, 159, 178) !important;
    font-size: 400, 1;
    color: white;
    font-size: 14px;
    padding: 5px, 5px;
    width: 100%;
    height: 75px;
    position: absolute;
}

footer hr {
    color: white;
    margin-top: 0px;
    margin-bottom: 0px;
    height: 5px;
}

.text-justify {
    top: 5px;
    text-align: justify;
}

.ant-tabs-content-holder {
    padding-top: 16px;
    margin-top: -16px;
    border: 1px solid #f0f0f0;
    border-top: 0px;
}

.card-no-bg {
    background: none !important;
}

/* page verify */
.fondo {
    background-image: url(../../Assets/images/bg/image-svg.webp);
    background-position: center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: fixed;
    width: 100%;
    height: 100vh;
    right: 0px;
    left: 0px;
    top: 0;
}

.logo-verify {
    z-index: 2;
    position: absolute;
    width: 20%;
    top: 0;
}

.verification strong {
    color: black;
    font-weight: 500;
    font-style: italic;
}

@media (max-width: 640px) {
    body {
        font-size: small;
    }
    .p-5 {
        padding: 0 !important;
    }

    #fondo {
        background-position: right;
        right: 0px;
        left: 0px;
        top: 0px;
    }
    .programs img {
        width: 35%;
        margin: 20px;
    }
    #infoince p {
        margin-top: 0px;
        margin-left: 0px;
    }
    #info-2 .card {
        height: auto;
    }
    #cintillo {
        background-color: rgb(242, 242, 242);
        background-position: center;
        background-size: contain;
        margin: 0px;
    }
    #cintillo .showBatalla {
        display: none;
    }
    .pre-footer {
        font-size: 12 px;
        right: 0px;
        left: 0px;
    }
    .pre-footer h4 {
        text-align: center;
    }
    footer {
        font-size: 13px;
    }

    /* page verify */
    .logo-verify {
        position: relative;
        margin: 0;
        top: 0;
        width: 40%;
    }
    .fondo {
        background-size: cover;
        position: fixed;
        width: 100%;
        height: 100vh;
        right: 0px;
        left: 0px;
        top: 0;
    }
}

.orbit-spinner,
.orbit-spinner * {
    box-sizing: border-box;
}

.orbit-spinner {
    height: 55px;
    width: 55px;
    border-radius: 50%;
    perspective: 800px;
}

.orbit-spinner .orbit {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.orbit-spinner .orbit:nth-child(1) {
    left: 0%;
    top: 0%;
    animation: orbit-spinner-orbit-one-animation 1200ms linear infinite;
    border-bottom: 3px solid #029fb2;
}

.orbit-spinner .orbit:nth-child(2) {
    right: 0%;
    top: 0%;
    animation: orbit-spinner-orbit-two-animation 1200ms linear infinite;
    border-right: 3px solid #0178bc;
}

.orbit-spinner .orbit:nth-child(3) {
    right: 0%;
    bottom: 0%;
    animation: orbit-spinner-orbit-three-animation 1200ms linear infinite;
    border-top: 3px solid #1890ff;
}

@keyframes orbit-spinner-orbit-one-animation {
    0% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
    }
}

@keyframes orbit-spinner-orbit-two-animation {
    0% {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
    }
}

@keyframes orbit-spinner-orbit-three-animation {
    0% {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
    }
}
