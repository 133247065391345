/*
Template Name: Monster Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
/*
Template Name: Monster Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
/*Theme Colors*/
/*bootstrap Color*/
/*Light colors*/
/*Normal Color*/
/*Extra Variable*/
/*Preloader*/
.preloader {
    width: 100%;
    height: 100%;
    top: 0px;
    position: fixed;
    z-index: 99999;
    background: #fff;
}

.preloader .cssload-speeding-wheel {
    position: absolute;
    top: calc(50% - 3.5px);
    left: calc(50% - 3.5px);
}

/*******************
/*Top bar
*******************/
.topbar {
    background: #009efb;
    /* Old browsers */
    background: -moz-linear-gradient(left, #0178bc 0%, #00bdda 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #0178bc 0%, #00bdda 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to left, #0178bc 0%, #029fb2 50%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.topbar .navbar-header {
    background: #ffffff;
    box-shadow: 4px -4px 10px rgba(0, 0, 0, 0.05);
}

.topbar .navbar-light .navbar-nav .nav-item > a.nav-link {
    color: rgba(255, 255, 255, 0.8) !important;
}

.topbar .navbar-light .navbar-nav .nav-item > a.nav-link:hover,
.topbar .navbar-light .navbar-nav .nav-item > a.nav-link:focus {
    color: #ffffff !important;
}

.logo-center .topbar .navbar-header {
    background: transparent;
    box-shadow: none;
}

.logo-center .topbar .top-navbar .navbar-header .navbar-brand .dark-logo {
    display: none;
}

.logo-center .topbar .top-navbar .navbar-header .navbar-brand .light-logo {
    display: inline-block;
    color: rgba(255, 255, 255, 0.8);
}

/*******************
/*General Elements
*******************/
a.link:hover,
a.link:focus {
    color: #009efb !important;
}

.bg-theme {
    background-color: #009efb !important;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
    background-color: #009efb;
    border-color: #009efb;
}

.right-sidebar .rpanel-title {
    background: #009efb;
}

.stylish-table tbody tr:hover,
.stylish-table tbody tr.active {
    border-left: 4px solid #009efb;
}

.text-themecolor {
    color: #009efb !important;
}

.profile-tab li a.nav-link.active,
.customtab li a.nav-link.active {
    border-bottom: 2px solid #009efb;
    color: #009efb;
}

.profile-tab li a.nav-link:hover,
.customtab li a.nav-link:hover {
    color: #009efb;
}

/*******************
/*Buttons
*******************/
.btn-themecolor,
.btn-themecolor.disabled {
    background: #009efb;
    color: #ffffff;
    border: 1px solid #009efb;
}

.btn-themecolor:hover,
.btn-themecolor.disabled:hover {
    background: #009efb;
    opacity: 0.7;
    border: 1px solid #009efb;
}

.btn-themecolor.active,
.btn-themecolor:focus,
.btn-themecolor.disabled.active,
.btn-themecolor.disabled:focus {
    background: #028ee1;
}

/*******************
/*sidebar navigation
*******************/
.sidebar-nav {
    background: #fff;
}

.sidebar-nav ul li a {
    color: #54667a;
}

.sidebar-nav ul li a.active,
.sidebar-nav ul li a:hover {
    color: #009efb;
}

.sidebar-nav ul li a.active {
    color: #263238;
}

.sidebar-nav ul li.nav-small-cap {
    color: #90a4ae;
}

.sidebar-nav > ul > li.active > a {
    border-left: 3px solid #009efb;
    color: #009efb;
}

.sidebar-nav > ul > li.active > a i {
    color: #009efb;
}

.sidebar-nav > ul > li > a {
    border-left: 3px solid #fff;
}

.sidebar-nav > ul > li > a.active,
.sidebar-nav > ul > li > a:hover {
    border-left: 3px solid #009efb;
}

.sidebar-nav > ul > li > a.active i,
.sidebar-nav > ul > li > a:hover i {
    color: #009efb;
}

.sidebar-nav > ul > li > a i {
    color: #a6b7bf;
}

.sidebar-nav > ul > li > a.active {
    font-weight: 400;
    background: #ffffff;
    color: #009efb;
}
